<!--
 * @Descripttion:
 * @version:
 * @Author: 郭梦霞
 * @Date: 2023-11-19 13:36:07
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-06-05 15:21:13
-->
<template>
  <div>
    <el-card>
      <div class="return-title">
        <div class="back-btn" @click="_backBtn"><i class="el-icon-back mr10"></i>返回</div>
        <span class="division">|</span>
        <div class="back-title">考试详情</div>
      </div>
      <!-- 考试结果 -->
      <div class="exam-result">
        <com-title title="考试结果"/>
        <div class="exam-result-content">
          <div class="result-left">
            <div class="result-img">
              <img src="@/assets/img/icon_data.png" alt="">
            </div>
            <div>
              <span>应考</span>
              <span>{{examInfoResult.testerNum||'0'}}</span>
            </div>
            <div>
              <span>已通过</span>
              <span>{{examInfoResult.passNum||'0'}}</span>
            </div>
            <div>
              <span>未通过</span>
              <span>{{examInfoResult.notPassNum||'0'}}</span>
            </div>
            <div>
              <span>未考试</span>
              <span>{{examInfoResult.notExamNum||'0'}}</span>
            </div>
          </div>
          <div class="result-right">
            <div class="result-img">
              <img src="@/assets/img/icon_Mark data.png" alt="">
            </div>
            <div>
              <span>平均分</span>
              <span>{{examInfoResult.avgMark||'0'}}</span>
            </div>
            <div>
              <span>最高分</span>
              <span>{{examInfoResult.maxMark||'0'}}</span>
            </div>
            <div>
              <span>最低分</span>
              <span>{{examInfoResult.minMark||'0'}}</span>
            </div>
          </div>
        </div>
      </div>
      <el-form label-width="44px">
        <!-- 基本信息 -->
        <div class="exam-baseInfo">
          <com-title title="基本信息" />
          <div class="baseinfo-content">
            <el-row>
              <el-col :span="8">
                <el-form-item label="考试名称">{{baseInfo.examName?baseInfo.examName:'—'}}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="试卷名称">
                  <span :style="{'color':$store.state.settings.theme}" @click="_previewPaper">
                    {{baseInfo.testPaperName?baseInfo.testPaperName:'—'}}
                  </span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="答题时长">{{baseInfo.duration?baseInfo.duration:'—'}}</el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="考试通过分数线">{{baseInfo.gradeThreshold?baseInfo.gradeThreshold:'—'}}<span style="color:#999;"> （{{baseInfo.sumScore||0}}分）</span></el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="考试时间">{{baseInfo.startTime?`${baseInfo.startTime}~${baseInfo.endTime}`:'—'}}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="答案解析设置">{{baseInfo.analysisType==1?'考试完成后':baseInfo.analysisType==2?'不允许查看':'—'}}</el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="阅卷人">{{baseInfo.examiner?baseInfo.examiner:'—'}}</el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="考试介绍">{{baseInfo.introduce?baseInfo.introduce:'—'}}</el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
        <!-- 考生信息 -->
        <div class="exam-userInfo">
          <div class="com-title-btn">
            <com-title title="考生信息" />
            <div>
              <el-button type="primary" @click="_settingExamTime" style="margin-right:6px;" :disabled="baseInfo.startTime?true:false">设置考试时间</el-button>
              <el-button type="primary" @click="_tellDutyUser" :disabled="dutyUserDisabled">通知负责人</el-button>
            </div>
          </div>
          <div style="margin-top:16px;">
            <el-row>
              <el-col :span="6">
                <el-form-item label="姓名">
                  <el-input v-model="examUserInfoForm.testerName" placeholder="请输入姓名" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-button @click="_resetExamStudent">重置</el-button>
                <el-button type="primary" style="margin-left:16px;" @click="_searchExamStudent">查询</el-button>
              </el-col>
            </el-row>
          </div>
          <div>
            <el-table
              :data="userInfoData"
              ref="multipleTable"
              @selection-change="_handleSelectionChange"
              :row-key="getRowKeys"
            >
              <el-table-column type="selection" min-width="55" fixed="left"  :selectable="_checkSelectable" :reserve-selection="true">
              </el-table-column>
              <el-table-column
                v-for="(item, index) in userInfoColumns"
                :key="index"
                :label="item.label"
                :prop="item.prop"
                :width="item.width"
                :show-overflow-tooltip="item.overflow"
              >
                <template slot-scope="scope">
                  <span v-if="item.prop === 'employeeName'" :style="{'color':$store.state.settings.theme}" @click.stop="_toPaperDetailByName(scope.row)">{{ scope.row[item.prop]?scope.row[item.prop]:'—' }}</span>
                  <div style="display: flex; align-items: center;" v-else-if="item.prop === 'correctStatus'">
                    <span id="dot-box" :class="scope.row.correctStatus==2?'orange':
                    scope.row.correctStatus==1?'red':
                    scope.row.correctStatus==3?'green':''"></span>
                    <span @click.stop="_toMarkPaper(scope.row)" :style=" scope.row.correctStatus==2?{'color':$store.state.settings.theme}:{}">
                      {{ scope.row.correctStatus == 0?(baseInfo.status == 2?'未考试':'待考试'): scope.row.correctStatus==1?'等待交卷': scope.row.correctStatus==2?'待审阅': scope.row.correctStatus==3?'已完成': '—'}}
                    </span>
                  </div>
                  <div v-else-if="item.prop === 'examStartTime'">
                    <span v-if="scope.row.examStartTime">{{ scope.row.examStartTime}}~{{ scope.row.examEndTime}}</span>
                    <span v-else>—</span>
                  </div>
                  <span v-else>
                    {{ scope.row[item.prop]!==undefined&&scope.row[item.prop]!==null&&scope.row[item.prop]!==''?scope.row[item.prop]:'—' }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button type="text" class="text-btn mr24" @click="_againMark(scope.row)" v-if="scope.row.correctStatus==2||scope.row.correctStatus==3">批阅</el-button>
                <el-button type="text" class="text-btn mr24" @click="_againExam(scope.row)" v-if="scope.row.correctStatus==2||scope.row.correctStatus==3">重新考试</el-button>
              </template>
            </el-table-column>
            </el-table>
            <!-- 考生信息--分页开始 -->
            <div>
              <el-pagination
                class="fx-row-end pt16"
                @size-change="_handleSizeChange"
                @current-change="_handleCurrentChange"
                :current-page="examUserInfoForm.page"
                :page-sizes="[5, 10, 20, 30, 40]"
                :page-size="examUserInfoForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="studentTotal">
              </el-pagination>
            </div>
            <!-- 考生信息--分页结束 -->
          </div>
        </div>
        <!--试卷分析  -->
        <div class="exam-paperAnalyse">
          <div class="com-title-btn">
            <com-title title="试卷分析" />
            <el-button type="primary" @click="_examAnalyseExport">导出</el-button>
          </div>
          <div style="margin-top:16px;">
            <el-row>
              <el-col :span="6">
                <el-form-item label="分组">
                  <el-select v-model="paperAnalyseForm.groupName" value-key="groupSort" placeholder="请选择" style="width:100%;">
                    <el-option
                      v-for="item in groupNameList"
                      :key="item.groupSort"
                      :value="item.groupName"
                      :label="item.groupName"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="题目">
                  <el-input v-model="paperAnalyseForm.title" placeholder="请输入题目"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-button @click="_resetExamAnalyse">重置</el-button>
                <el-button type="primary" style="margin-left:16px;" @click="_searchExamAnalyse">查询</el-button>
              </el-col>
            </el-row>
          </div>
          <div>
            <el-table :data="paperAnalyseData" max-height="560">
              <el-table-column
                v-for="(item, index) in examPaperColumns"
                :key="index"
                :label="item.label"
                :prop="item.prop"
                :width="item.width"
                :show-overflow-tooltip="item.overflow"
              >
                <template slot-scope="scope">
                  <span v-if="item.prop=='sort'">
                    {{scope.row[item.prop]!==undefined&&scope.row[item.prop]!==null&&scope.row[item.prop]!==''? Number(scope.row[item.prop])+1:'—'}}
                  </span>
                  <span v-else-if="item.prop=='accuracyRate'">{{scope.row.accuracyRate!=undefined&&scope.row.accuracyRate!=null&&scope.row.accuracyRate!=''?`${scope.row.accuracyRate}%`:'—'}}</span>
                  <span v-else>{{ scope.row[item.prop]!==undefined&&scope.row[item.prop]!==null&&scope.row[item.prop]!==''?scope.row[item.prop]:'—' }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-form>
    </el-card>
    <!-- 预览试卷--弹框--开始 -->
    <previewExamPaper
      v-if="previewExamShow"
      :previewExamShow="previewExamShow"
      :paperId="paperId"
      :questionsGroupList="questionsGroupList"
    ></previewExamPaper>
    <!-- 预览试卷--弹框--结束 -->
    <!-- 设置考试时间弹框--开始 -->
      <SetExamTime
      ref="settingExamTime"
      :settingType="settingType"
      @settingExamTime="_submitSettingExamTime"
      @againStartExam="_submitAgainStartExam"
      propTitle="设置考试时间"/>
      <!-- 设置考试时间弹框--结束 -->
  </div>
</template>
<script>
import { getExamArrangeDetailApi, getExamStudentListApi, getExamTopicAnalyseApi, getExamPreviewApi, getExamTopicAnalyseExportApi, getTellDutyUserApi, getAgainStartExamApi, getSettingExamTimeApi } from '@/api/examApi'
import previewExamPaper from '../../examPaperManage/modules/previewExamPaper.vue'
import SetExamTime from './setExamTime.vue'
import comTitle from '@/components/comTitle'
import { exportBtn } from '@/utils/util'
export default {
  components: { previewExamPaper, comTitle, SetExamTime },
  data () {
    return {
      baseInfo: {}, // 基本信息
      examInfoResult: {}, // 考试结果
      paperAnalyseData: [], // 考试分析
      examUserInfoForm: {
        testerName: '', // 姓名
        page: 1,
        pageSize: 10
      },
      dutyUserDisabled: false, // 通知负责人按钮
      previewExamShow: false,
      paperId: '', // 考试id
      questionsGroupList: [],
      studentTotal: 0,
      // --试卷分析
      paperAnalyseForm: {
        examInfoId: '', // 试卷id
        groupName: '', // 分组名称
        title: '', // 题目
        page: 1,
        pageSize: 500
      },
      groupNameList: [], // 考试分析--分组下拉
      // 考生信息
      userInfoColumns: [
        { prop: 'employeeName', label: '姓名', overflow: true },
        // { prop: 'name11', label: '工号', overflow: true },
        { prop: 'postName', label: '岗位', overflow: true },
        { prop: 'deptName', label: '部门', overflow: true },
        { prop: 'mark', label: '成绩' },
        { prop: 'examStartTime', label: '考试时间', overflow: true, width: 260 },
        { prop: 'submitTime', label: '交卷时间', overflow: true },
        { prop: 'correctStatus', label: '状态', overflow: true }
      ],
      // 试卷分析
      examPaperColumns: [
        { prop: 'groupName', label: '分组名称', overflow: true },
        { prop: 'sort', label: '序号', overflow: true },
        { prop: 'title', label: '题目', overflow: true },
        { prop: 'questionTypeValue', label: '题型', overflow: true },
        { prop: 'accuracyRate', label: '正确率' },
        { prop: 'errorNum', label: '出错人数', overflow: true },
        { prop: 'errorTester', label: '出错人', overflow: true },
        { prop: 'otherInstructions', label: '其他说明', overflow: true }
      ],
      userInfoData: [],
      tellDutyParams: {
        examInfoId: '',
        testerIds: []
      },
      allFinishStudentIds: [],
      settingType: '',
      multipleSelection: [] // 表格选中

    }
  },
  created () {
    if (this.$route.query.examId) {
      this._getExamArrangeDetail()
      this._getExamStudentList()// 获取考生信息
      this._getExamStudentList(1, 99990)
      this._getExamAnalyseList()// 获取考试分析
    }
  },
  methods: {
    // 获取考试安排详情
    _getExamArrangeDetail () {
      getExamArrangeDetailApi({ id: this.$route.query.examId }).then((res) => {
        if (res.code === 200) {
          this.baseInfo = res.data.basicInfo
          this.examInfoResult = res.data.examInfoResult
          this._getPreviewExamPaper(this.baseInfo)
        }
      })
    },
    // 获取预览试卷--试题信息
    _getPreviewExamPaper (item) {
      getExamPreviewApi({ examInfoId: item.id }).then((res) => {
        this.questionsGroupList = res.data
        if (this.questionsGroupList.length !== 0) {
          this.questionsGroupList.forEach((groupInfo, groupIndex) => {
            this._getNeedShowData(this.questionsGroupList, groupInfo, groupIndex)// 回显所需数据格式（整个数组，其中一个整个分组，分组下标）
          })
        }
      })
    },
    // 试卷预览-- 回显所需数据格式
    _getNeedShowData (groupTopicList, groupInfo, groupIndex) {
      groupInfo.groupIndex = groupIndex
      groupTopicList[groupIndex].questionInfo = groupTopicList[groupIndex].questionInfo.map((topicItem, topicIndex) => {
        return {
          ...topicItem,
          index: topicIndex,
          groupIndex: groupIndex,
          answerCheck: topicItem?.answer?.split(','),
          options: JSON.parse(topicItem?.options),
          contentUrl: topicItem?.contentUrl?.split(','),
          analysisUrl: topicItem?.analysisUrl.split(',')
        }
      })
    },
    // 预览试卷
    _previewPaper () {
      this.previewExamShow = true
    },
    // 考生信息列表
    _getExamStudentList (page, pageSize) {
      const params = {
        examInfoId: this.$route.query.examId,
        ...this.examUserInfoForm
      }
      if (page && pageSize) {
        params.page = page
        params.pageSize = pageSize
      }
      getExamStudentListApi({ ...params }).then((res) => {
        if (res.code === 200) {
          if (page && pageSize) { // 不能限制分页，通知负责人时，不带分页
            const tempUserInfoData = JSON.parse(JSON.stringify(res.object.results))
            const finishExamStudents = tempUserInfoData.filter((item) => item.correctStatus === 3)
            this.tellDutyParams.testerIds = finishExamStudents.map((item) => {
              return item.employeeId
            })
            this.allFinishStudentIds = this.tellDutyParams.testerIds
            if (this.tellDutyParams.testerIds.length === 0) {
              this.dutyUserDisabled = true
            } else {
              this.dutyUserDisabled = false
            }
          } else { // 普通考生信息表格（带分页）
            this.userInfoData = JSON.parse(JSON.stringify(res.object.results))
            this.studentTotal = res.object.total
          }
        }
      })
    },
    // 考生信息-分页
    _handleSizeChange (val) {
      this.examUserInfoForm.pageSize = val
      this._getExamStudentList()
    },
    // 考生信息-分页
    _handleCurrentChange (val) {
      this.examUserInfoForm.page = val
      this._getExamStudentList()
    },
    // 通知负责人
    _tellDutyUser () {
      this.tellDutyParams.examInfoId = this.$route.query.examId
      this._getExamStudentList(1, 90000)
      if (this.multipleSelection.length === 0) {
        this.tellDutyParams.testerIds = this.allFinishStudentIds // 未选择要通知的负责人，通知所有人的负责人
      } else { /// / 只通知勾选的要通知的负责人
        this.tellDutyParams.testerIds = this.multipleSelection.map((item) => {
          return item.employeeId
        })
      }
      getTellDutyUserApi({ ...this.tellDutyParams }).then((res) => {
        if (res.code === 200) {
          this.$message.success('通知成功！')
          this.$refs.multipleTable.clearSelection()
          this.multipleSelection = []
        }
      })
    },
    // 考试分析列表
    _getExamAnalyseList () {
      this.paperAnalyseForm.examInfoId = this.$route.query.examId
      getExamTopicAnalyseApi({ ...this.paperAnalyseForm }).then((res) => {
        if (res.code === 200) {
          this.paperAnalyseData = JSON.parse(JSON.stringify(res.object.results))
          this.groupNameList = this.paperAnalyseData.filter((item) => item.groupName !== '')
          this.groupNameList = this._deduplicate(this.groupNameList)
          console.log(this.groupNameList, 'this.groupNameList')
        }
      })
    },
    // 数组对象去重
    _deduplicate (arr) {
      const map = new Map()
      for (const item of arr) {
        if (!map.has(item.groupSort)) {
          map.set(item.groupSort, item)
        }
      }
      return [...map.values()]
    },
    // 查询考生
    _searchExamStudent () {
      this._getExamStudentList()
    },
    // 重置考生查询条件
    _resetExamStudent () {
      this.examUserInfoForm = {
        testerName: '', // 姓名
        page: 1,
        pageSize: 10
      }
      this._getExamStudentList()
    },
    // 查询考试分析
    _searchExamAnalyse () {
      this._getExamAnalyseList()
    },
    // 重置考试分析
    _resetExamAnalyse () {
      this.paperAnalyseForm = {
        examInfoId: this.$route.query.examId,
        groupName: '', // 分组名称
        title: '', // 题目
        page: 1,
        pageSize: 500
      }
      this._getExamAnalyseList()
    },
    _backBtn () {
      this.$router.push({
        path: '/examArrange'
      })
    },
    // 创建试卷
    _createPaper () {
      console.log('创建试卷!')
    },
    // 通过考生名字跳转到相应试卷详情
    _toPaperDetailByName (row) {
      console.log(row, 'hjh')
      if (row.correctStatus !== 3) {
        return this.$message.error('该试卷还未完成，不能查看！')
      }
      this.$router.push({
        path: '/markExamPaperDetail',
        query: {
          examInfoId: row.examInfoId,
          employeeId: row.employeeId
        }
      })
    },
    // 待批阅状态批阅状态
    _toMarkPaper (row) {
      console.log(row, this.baseInfo)
      if (row.correctStatus === 2) { // 已完成可再次批阅
        const tempExamer = this.baseInfo.examiner.split(',')
        if (tempExamer.indexOf(this.$store.state.userList.userName) === -1) {
          return this.$message.error('您不是指定批阅人员，不可批阅！')
        }
        this.$router.push({
          path: '/markExamPaper',
          query: {
            examInfoId: row.examInfoId,
            employeeId: row.employeeId,
            businessId: row.id
          }
        })
      }
    },
    // 重新批阅
    _againMark (row) {
      const tempExamer = this.baseInfo.examiner.split(',')
      if (tempExamer.indexOf(this.$store.state.userList.userName) === -1) {
        return this.$message.error('您不是指定批阅人员，不可批阅！')
      }
      this.$router.push({
        path: '/markExamPaper',
        query: {
          examInfoId: row.examInfoId,
          employeeId: row.employeeId,
          businessId: row.id
        }
      })
    },
    // 考试分析导出
    _examAnalyseExport () {
      // 导出
      exportBtn(getExamTopicAnalyseExportApi, { ...this.paperAnalyseForm, page: undefined, pageSize: undefined }, '试卷分析导出')
    },
    // 设置考试时间
    _settingExamTime () {
      this.settingType = 'multipleSetting'
      this.$refs.settingExamTime.show(null, this.userInfoData)
    },
    // 设置考试时间-- 确定
    _submitSettingExamTime (params) {
      getSettingExamTimeApi(params).then((res) => {
        if (res.code === 200) {
          this.$message.success('设置成功！')
          this._getExamStudentList()
        }
      })
    },
    // 重新考试
    _againExam (row) {
      this.settingType = 'singleSetting'
      this.$refs.settingExamTime.show(row, this.userInfoData)
    },
    // 重新考试==确定
    _submitAgainStartExam (params) {
      getAgainStartExamApi(params).then((res) => {
        if (res.code === 200) {
          this.$message.success('操作成功！')
          this._getExamStudentList()
        }
      })
    },
    // 分页多选
    getRowKeys (row) {
      return row.id // 指定table id
    },
    // 表格选择
    _handleSelectionChange (val) {
      let arr = []
      if (val.length !== 0) {
        arr = val.filter(item => {
          return item.correctStatus === 3
        })
      }
      this.multipleSelection = arr
    },
    // 已完成的可勾选通知负责人
    _checkSelectable (row) {
      if (row.correctStatus !== 3) { // 禁用不可选行
        return false
      } else {
        return true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../style.scss';
// 头部返回标题样式
.return-title{
  display: flex;
  align-items: center;
  .back-btn{
    font-size: 14px;
    color: #303133;
  }
  .division{
    color: #F3F3F3;
    width: 1px;
    background-color: #F3F3F3;
    border-radius: 2px;
    margin: 0px 16px;
  }
  .back-title{
    font-size: 16px;
    color: #333333;
  }
}
// 考试结果
.exam-result{
  margin: 24px 0px;
  .exam-result-content{
    margin-top: 16px;
    display: flex;
    height: 92px;
    justify-content:space-between;
    .result-left,.result-right{
      width: 44%;
      padding: 16px 24px;
      background: linear-gradient(to bottom, #f0f8ff, #ffffff);
      border: 1px solid #e7f4ff;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      div{
        flex:1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        span:first-child{
          color: #4D4D4D;
          font-size: 14px;
          padding-bottom: 4px;
        }
        span:last-child{
          color: #333333;
          font-size: 18px;
          padding-top: 4px;

        }
      }
      // 图片
      .result-img{
        img{
          width: 60px;
          height: 60px;
        }
      }
    }
  }
}
// 基本信息
.exam-baseInfo{
  margin-top: 8px;
  .baseinfo-content{
    margin-top: 16px;
    /deep/.el-form-item__label{
      height: 20px;
      line-height: 20px;
      width: 118px!important;
      color: #999;
    }
    /deep/.el-form-item__content{
      height: 20px;
      line-height: 20px;
      margin-left: 118px!important;
      color: rgba(0, 0, 0, 0.9);
    }
  }
}
// 考生信息
.exam-userInfo{
  margin-top: 14px;
}
// 试卷分析
.exam-paperAnalyse{
  margin-top:32px;
}
.com-title-btn{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
